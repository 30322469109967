export async function copyText(text) {
  await navigator.clipboard.writeText(text)
}

export function downloadFile(url) {
  const iframe = document.createElement('iframe')
  iframe.setAttribute('sandbox', 'allow-downloads allow-scripts')
  iframe.setAttribute('style', 'display: none')
  iframe.src = url
  document.body.appendChild(iframe)
}

export function stringToFile(data, filename) {
  const blob = new Blob([data], {type: 'text/plain'})
  const link = document.createElement('a')
  link.download = filename
  link.href = URL.createObjectURL(blob)
  link.click()
  URL.revokeObjectURL(link.href)
}