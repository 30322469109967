export function replaceTag(node, replace_with){
  const new_el = typeof replace_with === 'string' ? document.createElement(replace_with) : replace_with
  Array.from(node.attributes).forEach(attr => new_el.setAttribute(attr.name, attr.value))
  new_el.innerHTML = node.innerHTML
  node.replaceWith(new_el)
  return new_el
}

export function removeTags(node, selector){
  const nodes = node.querySelectorAll(selector)
  nodes.forEach(n => n.remove())
}

export function replaceTags(node, selector, replace_with){
  const elements = node.querySelectorAll(selector)
  elements.forEach((el, i) => replaceTag(el, Array.isArray(replace_with) ? replace_with[i] : replace_with))
}